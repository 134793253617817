.xflow-default-node {
  z-index: 10;
  display: flex;
  width: 180px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #1890ff;
  border-radius: 2px;
  box-shadow: -1px -1px 4px 0 rgba(223,223,223,0.50), -2px 2px 4px 0 rgba(244,244,244,0.50), 2px 3px 8px 2px rgba(151,151,151,0.05);
  transition: all ease-in-out 0.15s;
}
.xflow-default-node:hover {
  background-color: #fff;
  border: 1px solid #3057e3;
  box-shadow: 0 0 3px 3px rgba(48, 86, 227, 0.15);
  cursor: move;
}
.xflow-default-node .icon {
  width: 36px;
}
.xflow-default-node .label {
  width: 108px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.xflow-default-node .status {
  width: 36px;
}
.xflow-default-node.panel-node {
  border: 0;
}
.x6-node-selected .xflow-default-node {
  background-color: rgba(48, 86, 227, 0.05);
  border: 1px solid #3057e3;
  box-shadow: 0 0 3px 3px rgba(48, 86, 227, 0.15);
}
.x6-node-selected .xflow-default-node:hover {
  background-color: #fff;
  box-shadow: 0 0 5px 5px rgba(48, 86, 227, 0.15);
}
.xflow-default-group-node {
  z-index: 9;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.65);
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 4px;
  box-shadow: rgb(17 49 96 / 12%) 0px 1px 3px 0px, rgb(17 49 96 / 4%) 0px 0px 0px 1px;
  cursor: grab;
}
.xflow-default-group-node:hover {
  background-color: rgba(227, 244, 255, 0.45);
  border: 1px solid #1890ff;
  box-shadow: 0 0 3px 3px rgba(64, 169, 255, 0.2);
  cursor: move;
}
.xflow-default-group-node .xflow-group-header {
  display: flex;
  justify-content: space-between;
  padding: 0 12px;
  font-size: 14px;
  line-height: 38px;
}
.xflow-default-group-node .xflow-group-header .header-left {
  width: 80%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.xflow-default-group-node .xflow-group-header .header-right {
  display: inline-flex;
  align-items: center;
}
.xflow-default-group-node .xflow-group-header .header-right span.anticon {
  margin-left: 8px;
}
.x6-node-selected .xflow-default-group-node {
  background-color: rgba(243, 249, 255, 0.92);
  border: 1px solid #1890ff;
  box-shadow: 0 0 3px 3px rgba(64, 169, 255, 0.2);
}
.x6-node-selected .xflow-default-group-node:hover {
  background-color: rgba(243, 249, 255, 0.6);
}
.xflow-canvas-root {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.xflow-x6-canvas {
  width: 100%;
  height: 100%;
}
.xflow-app-workspace {
  position: relative;
  width: 100%;
  height: 100%;
}
.xflow-hide {
  display: none;
}
.x6-widget-dnd.dragging {
  cursor: grabbing !important;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.xflow-collapse-panel {
  border-right: 1px solid #d9d9d9;
}
.xflow-collapse-panel .disabled {
  position: relative;
  opacity: 0.45;
}
.xflow-collapse-panel .disabled::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  cursor: not-allowed;
  content: '';
}
.xflow-collapse-panel-node-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: grab;
}
.xflow-collapse-panel-header {
  display: flex;
  justify-content: space-evenly;
  background: #fff;
  border-bottom: 1px solid #d9d9d9;
}
.xflow-collapse-panel-header-title {
  font-size: 16px;
}
.xflow-collapse-panel-header-search {
  width: 100%;
  padding: 0 12px;
}
.xflow-collapse-panel-body {
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background: #fff;
}
.xflow-collapse-panel-footer {
  display: flex;
  justify-content: space-evenly;
  background: #fff;
  border-top: 1px solid #d9d9d9;
}
.xflow-collapse-panel-footer-title {
  font-size: 16px;
}
.xflow-collapse-panel .xflow-collapse-search-list {
  height: 100%;
  margin: 0;
  padding: 8px 0;
  overflow-y: auto;
  list-style: none;
}
.xflow-collapse-panel .xflow-collapse-search-list-item {
  margin: 0;
  padding: 4px 0;
}
.xflow-collapse-panel .xflow-collapse-list {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-y: auto;
  list-style: none;
}
.xflow-collapse-panel .xflow-collapse-list-item {
  margin: 0;
  padding: 0;
}
.xflow-collapse-panel .xflow-collapse-list-item.close .xflow-collapse-content {
  height: 0;
  transform: scaleY(0);
}
.xflow-collapse-panel .xflow-collapse-header {
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 1.5;
  cursor: pointer;
  transition: all 0.3s;
  user-select: none;
}
.xflow-collapse-panel .xflow-collapse-header:hover {
  background: #f5f5f5;
}
.xflow-collapse-panel .xflow-collapse-header-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
}
.xflow-collapse-panel .xflow-collapse-header-icon svg {
  transition: all 0.5s;
}
.xflow-collapse-panel .xflow-collapse-header-label {
  flex: 1;
}
.xflow-collapse-panel .xflow-collapse-header-extra {
  width: auto;
}
.xflow-collapse-panel .xflow-collapse-content {
  height: 100%;
  height: auto;
  overflow: hidden;
  transform: scaleY(1);
  transition: scale 0.15s ease-in-out;
}
.xflow-collapse-panel .xflow-collapse-content-item {
  padding: 4px 0;
  transition: all 0.5s;
}
.xflow-collapse-panel .xflow-collapse-content-item:hover {
  background: #f5f5f5;
}
.x6-menu {
  position: relative;
  display: inline-block;
  min-width: 160px;
  min-height: 32px;
  margin: 0;
  padding: 4px 0;
  background-color: #fff;
  outline: 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.12);
}
.x6-menu-item {
  position: relative;
}
.x6-menu-item-active > .x6-menu-item-button,
.x6-menu-item:hover > .x6-menu-item-button {
  color: #262626;
  background: #f5f5f5;
}
.x6-menu-item-divider {
  display: block;
  width: 100%;
  height: 1px;
  margin: 4px 0;
  background: rgba(150, 150, 150, 0.2);
  pointer-events: none;
}
.x6-menu-item-button {
  position: relative;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 28px;
  padding: 0 12px;
  color: #595959;
  text-align: left;
  background: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
}
.x6-menu-item-hidden {
  display: none;
}
.x6-menu-item-disabled > .x6-menu-item-button,
.x6-menu-item-disabled:hover > .x6-menu-item-button {
  color: #595959;
  background-color: transparent;
  cursor: not-allowed;
  opacity: 0.4;
}
.x6-menu-item-icon {
  position: absolute;
  top: 50%;
  left: 6px;
  display: none;
  width: 24px;
  height: 24px;
  margin-top: -12px;
  font-size: 13px;
}
.x6-menu-item-text {
  padding-right: 56px;
  overflow: hidden;
  font-size: 13px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.x6-menu-item-hotkey {
  font-size: 13px;
  opacity: 0.75;
}
.x6-menu-submenu-arrow,
.x6-menu-submenu.x6-menu-item-disabled:hover > .x6-menu-item-button > .x6-menu-submenu-arrow {
  position: absolute;
  top: 10px;
  right: 12px;
  width: 0;
  height: 0;
  border-top: 4px solid rgba(0, 0, 0, 0);
  border-bottom: 4px solid rgba(0, 0, 0, 0);
  border-left: 5px solid #262626;
  opacity: 0.4;
  pointer-events: none;
}
.x6-menu-submenu-menu,
.x6-menu-submenu.x6-menu-item-disabled:hover > .x6-menu-submenu-menu {
  position: absolute;
  top: -5px;
  left: 100%;
  z-index: 9999;
  min-width: 200px;
  margin-left: -4px;
  padding: 5px 0;
  background: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.12);
  transform: translateX(-10px);
  visibility: hidden;
  opacity: 0;
  transition: all 0.25s cubic-bezier(0.3, 1.2, 0.2, 1);
}
.x6-menu-submenu.x6-menu-item-active > .x6-menu-item-button > .x6-menu-submenu-arrow,
.x6-menu-submenu:hover > .x6-menu-item-button > .x6-menu-submenu-arrow {
  opacity: 0.75;
}
.x6-menu-submenu.x6-menu-item-active > .x6-menu-submenu-menu,
.x6-menu-submenu:hover > .x6-menu-submenu-menu {
  transform: translateX(0);
  visibility: visible;
  opacity: 1;
}
.x6-menu.x6-menu-has-icon .x6-menu-item-button {
  padding-left: 30px;
}
.x6-menu.x6-menu-has-icon .x6-menu-item-button .x6-menu-item-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.x6-menu.x6-menu-has-icon .x6-menu-item-button .x6-menu-item-text {
  padding-left: 2px;
}
.xflow-menu-mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
}
.xflow-menu-mask .x6-dropdown {
  overflow: visible;
  pointer-events: all;
}
.xflow-menu-mask .xflow-context-menu-anchor {
  position: absolute;
  z-index: 1;
  background-color: #fff;
}
@keyframes xflow-processing-line {
  to {
    stroke-dashoffset: -1000;
  }
}
.dag-extension-container .x6-edge {
  stroke-width: 1px;
}
.dag-extension-container .x6-edge.success path:nth-child(2) {
  stroke: #d5d5d5 !important;
}
.dag-extension-container .x6-edge.success path:nth-child(3) {
  fill: #d5d5d5 !important;
  stroke: #d5d5d5 !important;
}
.dag-extension-container .x6-edge.error {
  stroke-width: 2px;
}
.dag-extension-container .x6-edge.error path:nth-child(2) {
  stroke: rgba(245, 34, 45, 0.45) !important;
  stroke-width: 2px;
}
.dag-extension-container .x6-edge.processing path:nth-child(2) {
  animation: xflow-processing-line 30s infinite linear;
  stroke: rgba(57, 202, 116, 0.8);
  stroke-width: 2px;
  stroke-dasharray: 8px, 2px;
}
.dag-extension-container .x6-edge.x6-edge-selected path:nth-child(2) {
  stroke: #1890ff;
  stroke-width: 2px;
}
.dag-extension-container .x6-edge:hover path:nth-child(2) {
  stroke: #1890ff;
  stroke-width: 2px;
}
.dag-extension-container .x6-edge.hoverHighlight path:nth-child(2) {
  stroke: #1890ff;
  stroke-width: 2px;
}
.dag-extension-container .x6-port .xflow-port-group .xflow-port-arrow {
  display: none;
}
.dag-extension-container .x6-port .xflow-port-group.available .xflow-port {
  stroke: rgba(57, 202, 116, 0.6);
  r: 8;
  stroke-width: 8px;
}
.dag-extension-container .x6-port .xflow-port-group.connected .xflow-port-arrow {
  display: block;
}
.dag-extension-container .x6-port .xflow-port-group.connected .xflow-port {
  display: none;
}
.dag-extension-container .x6-port .xflow-port-group.adsorbed .xflow-port {
  stroke: rgba(57, 202, 116, 0.85);
  r: 10;
  stroke-width: 10px;
}
.layout-top-bottom .x6-port .xflow-port-group .xflow-port-arrow {
  display: none;
}
.layout-top-bottom .x6-port .xflow-port-group.connected .xflow-port-arrow {
  display: block;
}
.layout-top-bottom .x6-port .xflow-port-group.connected .xflow-port {
  display: none;
}
.layout-left-right .x6-port .xflow-port-group .xflow-port-arrow {
  display: none;
}
.layout-left-right .x6-port .xflow-port-group.connected .xflow-port-arrow {
  display: block;
  transform: translate(0, 5px) rotate(270deg) !important;
}
.layout-left-right .x6-port .xflow-port-group.connected .xflow-port {
  display: none;
}
.flow-extension-container .x6-edge {
  stroke-width: 1px;
}
.flow-extension-container .x6-edge.success path:nth-child(2) {
  stroke: #888 !important;
}
.flow-extension-container .x6-edge.success path:nth-child(3) {
  fill: #888 !important;
  stroke: #888 !important;
}
.flow-extension-container .x6-edge.error {
  stroke-width: 2px;
}
.flow-extension-container .x6-edge.error path:nth-child(2) {
  stroke: rgba(245, 34, 45, 0.45) !important;
  stroke-width: 2px;
}
.flow-extension-container .x6-edge.guideProcessing path:nth-child(2) {
  stroke: rgba(57, 202, 116, 0.8);
  stroke-width: 2px;
  stroke-dasharray: 8px, 2px;
}
.flow-extension-container .x6-edge.guideProcessing path:nth-child(2):local {
  animation: processing-line 30s infinite linear;
}
.flow-extension-container .x6-edge.x6-edge-selected path:nth-child(2) {
  stroke: #1890ff;
  stroke-width: 2px;
}
.flow-extension-container .x6-edge:hover path:nth-child(2) {
  stroke: #1890ff;
  stroke-width: 2px;
}
.flow-extension-container .x6-edge.hoverHighlight path:nth-child(2) {
  stroke: #1890ff;
  stroke-width: 2px;
}
@keyframes processing-line {
  to {
    stroke-dashoffset: -1000;
  }
}
.flow-extension-container .x6-widget-transform {
  margin: -1px 0 0 -1px;
  padding: 0;
  border: 1px solid #239edd;
}
.flow-extension-container .x6-widget-transform > div {
  border: 1px solid #239edd;
}
.flow-extension-container .x6-widget-transform > div:hover {
  background-color: #3dafe4;
}
.flow-extension-container .x6-widget-transform-active-handle {
  background-color: #3dafe4;
}
.flow-extension-container .x6-widget-transform-resize {
  border-radius: 0;
}
.flow-extension-container .x6-widget-selection-inner {
  border: 1px solid #239edd;
}
.flow-extension-container .x6-widget-selection-box {
  opacity: 0;
}
.xflow-form-checkbox {
  margin-bottom: 2px !important;
}
.xflow-form-checkbox :global .ant-checkbox-wrapper {
  width: 100%;
  overflow: hidden;
}
.xflow-form-input :global .ant-input-clear-icon {
  color: rgba(0, 0, 0, 0.25);
}
.xflow-json-form {
  height: 100%;
}
.xflow-json-form label {
  font-size: 12px;
}
.xflow-json-form .ant-form-item {
  margin-bottom: 12px;
}
.xflow-json-form .ant-form-item .ant-form-item-label {
  padding-bottom: 4px;
}
.xflow-json-form .ant-form-item .ant-form-item-label > label {
  color: #666;
}
.xflow-json-form .ant-form-item .ant-form-item-control .ant-checkbox-wrapper {
  color: #666;
}
.xflow-json-form .ant-form-item-explain,
.xflow-json-form .ant-form-item-extra {
  font-size: 12px;
}
.xflow-json-form .ant-form-item-explain-error > div {
  word-break: break-all;
}
.xflow-json-form .ant-form-item-extra {
  margin-top: 4px;
}
.xflow-json-form .tabs {
  flex-direction: column;
  height: 100%;
}
.xflow-json-form .tabs .ant-tabs-nav {
  box-shadow: 0 0 16px -5px rgba(0, 0, 0, 0.2);
}
.xflow-json-form .tabs .ant-tabs-nav .ant-tabs-nav-list {
  width: 100%;
}
.xflow-json-form .tabs .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab {
  font-size: 12px;
}
.xflow-json-form .tabs .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab-active {
  border-bottom-width: 0;
}
.xflow-json-form .tabs .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn {
  font-weight: normal;
  text-align: center;
}
.xflow-json-form .tabs .ant-tabs-content-holder {
  padding: 0 16px;
  overflow-y: auto;
}
.xflow-json-form .tabs.xTab .ant-tabs-nav .ant-tabs-nav-list {
  box-sizing: border-box;
  height: 40px;
  background-color: #fff;
  border-bottom: 1px solid #d9d9d9;
}
.xflow-json-form .tabs.xTab .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab {
  background-color: #fff;
}
.xflow-json-form .tabs.xTab .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn {
  width: 100%;
}
.xflow-json-form .tabs.singleTab .ant-tabs-tab {
  width: 100%;
}
.xflow-json-form .tabs.coupleTab .ant-tabs-tab {
  width: 50%;
  margin-right: 0;
  border-right-width: 0;
}
.xflow-json-form .tabs.coupleTab .ant-tabs-tab:last-of-type {
  border-right-width: 1px;
}
.xflow-json-form .tabs.ternateTab .ant-tabs-tab {
  width: 33%;
  margin-right: 0;
  border-right-width: 0;
}
.xflow-json-form .tabs.ternateTab .ant-tabs-tab:last-of-type {
  border-right-width: 1px;
}
.xflow-json-schema-form {
  border-left: 1px solid #d9d9d9;
}
.xflow-json-schema-form-body {
  position: relative;
  width: 100%;
  height: 100%;
}
.xflow-json-schema-form-header {
  display: flex;
  justify-content: space-evenly;
  background: #fff;
  border-bottom: 1px solid #d9d9d9;
}
.xflow-json-schema-form-header-title {
  font-size: 16px;
}
.xflow-json-schema-form-footer {
  display: flex;
  justify-content: space-evenly;
  background: #fff;
  border-top: 1px solid #d9d9d9;
}
.xflow-json-schema-form-footer-title {
  font-size: 16px;
}
.text-truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.xflow-minimap {
  z-index: 999;
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
}
.xflow-minimap .x6-widget-minimap .x6-graph {
  background: rgba(255, 255, 255, 0.9);
}
.xflow-minimap .x6-widget-minimap {
  background: rgba(34, 34, 34, 0.05);
}
.xflow-node-dnd-panel {
  border-right: 1px solid #d9d9d9;
}
.xflow-node-dnd-panel-header {
  display: flex;
  justify-content: space-evenly;
  background: #fff;
  border-bottom: 1px solid #d9d9d9;
}
.xflow-node-dnd-panel-header-title {
  font-size: 16px;
}
.xflow-node-dnd-panel-header-search {
  width: 100%;
  padding: 0 12px;
}
.xflow-node-dnd-panel-body {
  padding: 0;
  padding: 8px 0;
  overflow-x: hidden;
  overflow-y: auto;
  background: #fff;
}
.xflow-node-dnd-panel-body .ant-tree-iconEle.ant-tree-icon__customize {
  display: none !important;
  width: auto;
  margin-right: 0;
  color: rgba(0, 0, 0, 0.45);
}
.xflow-node-dnd-panel-body .ant-tree {
  background: unset;
}
.xflow-node-dnd-panel-body .ant-tree-switcher {
  display: flex;
  align-items: center;
  justify-content: center;
}
.xflow-node-dnd-panel-body .ant-tree-node-content-wrapper {
  display: flex;
  align-items: center;
  padding-left: 0;
  overflow: hidden;
}
.xflow-node-dnd-panel-body-list {
  margin: 0;
  padding: 0;
}
.xflow-node-dnd-panel-body-list-item {
  margin: 0;
  padding: 4px 12px;
  overflow: hidden;
  list-style-type: none;
}
.xflow-node-dnd-panel-body-list-item .xflow-node-dnd-panel-node-wrapper {
  justify-content: center;
}
.xflow-node-dnd-panel-tree-leaf {
  position: relative;
}
.xflow-node-dnd-panel-tree-leaf .ant-tree-iconEle {
  display: none;
}
.xflow-node-dnd-panel-tree-leaf .ant-tree-title {
  padding: 2px;
}
.xflow-node-dnd-panel-node-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}
.xflow-node-dnd-panel-footer {
  display: flex;
  justify-content: space-evenly;
  background: #fff;
  border-top: 1px solid #d9d9d9;
}
.xflow-node-dnd-panel-footer-title {
  font-size: 16px;
}
.xflow-dnd-node {
  width: 100%;
  height: 40px;
}
.x6-dropdown {
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 9999;
  display: block;
  max-height: 320px;
  overflow-y: scroll;
  border-radius: 3px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
}
.x6-dropdown-hidden {
  display: none;
}
.x6-dropdown-overlay {
  display: inline-block;
}
.x6-dropdown.slide-down-enter.slide-down-enter-active.x6-dropdown-placement-bottomLeft,
.x6-dropdown.slide-down-appear.slide-down-appear-active.x6-dropdown-placement-bottomLeft,
.x6-dropdown.slide-down-enter.slide-down-enter-active.x6-dropdown-placement-bottomCenter,
.x6-dropdown.slide-down-appear.slide-down-appear-active.x6-dropdown-placement-bottomCenter,
.x6-dropdown.slide-down-enter.slide-down-enter-active.x6-dropdown-placement-bottomRight,
.x6-dropdown.slide-down-appear.slide-down-appear-active.x6-dropdown-placement-bottomRight {
  animation-name: x6SlideUpIn;
}
.x6-dropdown.slide-up-enter.slide-up-enter-active.x6-dropdown-placement-topLeft,
.x6-dropdown.slide-up-appear.slide-up-appear-active.x6-dropdown-placement-topLeft,
.x6-dropdown.slide-up-enter.slide-up-enter-active.x6-dropdown-placement-topCenter,
.x6-dropdown.slide-up-appear.slide-up-appear-active.x6-dropdown-placement-topCenter,
.x6-dropdown.slide-up-enter.slide-up-enter-active.x6-dropdown-placement-topRight,
.x6-dropdown.slide-up-appear.slide-up-appear-active.x6-dropdown-placement-topRight {
  animation-name: x6SlideDownIn;
}
.x6-dropdown.slide-down-leave.slide-down-leave-active.x6-dropdown-placement-bottomLeft,
.x6-dropdown.slide-down-leave.slide-down-leave-active.x6-dropdown-placement-bottomCenter,
.x6-dropdown.slide-down-leave.slide-down-leave-active.x6-dropdown-placement-bottomRight {
  animation-name: x6SlideUpOut;
}
.x6-dropdown.slide-up-leave.slide-up-leave-active.x6-dropdown-placement-topLeft,
.x6-dropdown.slide-up-leave.slide-up-leave-active.x6-dropdown-placement-topCenter,
.x6-dropdown.slide-up-leave.slide-up-leave-active.x6-dropdown-placement-topRight {
  animation-name: x6SlideDownOut;
}
@keyframes x6SlideUpIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes x6SlideUpOut {
  0% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes x6SlideDownIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
}
@keyframes x6SlideDownOut {
  0% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
}
.x6-toolbar {
  display: flex;
  flex-direction: row;
  height: 28px;
  margin: 0;
  padding: 0;
  padding-left: 4px;
  overflow: hidden;
}
.x6-toolbar-content {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
}
.x6-toolbar-content-inner,
.x6-toolbar-content-extras,
.x6-toolbar-group,
.x6-toolbar-item,
.x6-toolbar-item-icon,
.x6-toolbar-item-text {
  display: flex;
  flex-direction: row;
}
.x6-toolbar-content-extras {
  align-content: center;
  align-items: center;
  font-size: 14px;
}
.x6-toolbar-group::before {
  align-self: center;
  width: 1px;
  height: 40%;
  margin: 0 6px;
  background-color: rgba(0, 0, 0, 0.15);
  content: ' ';
  pointer-events: none;
}
.x6-toolbar-group:first-child::before {
  content: none;
}
.x6-toolbar-item {
  align-content: center;
  align-items: center;
  margin: 0;
  padding: 0 4px;
  color: #595959;
  background-color: transparent;
  border: 0;
  border-radius: 2px;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  user-select: none;
}
.x6-toolbar-item.x6-toolbar-item-hidden {
  display: none;
}
.x6-toolbar-item.x6-toolbar-item-disabled,
.x6-toolbar-item.x6-toolbar-item-disabled:hover {
  cursor: not-allowed;
  opacity: 0.4;
}
.x6-toolbar-item.x6-toolbar-item-disabled .x6-toolbar-item-icon .x6-toolbar-item:active {
  transform: none;
}
.x6-toolbar-item-icon,
.x6-toolbar-item-text {
  align-content: center;
  align-items: center;
}
.x6-toolbar-item-icon {
  font-size: 14px;
  transition: transform 50ms ease;
}
.x6-toolbar-item-icon:active {
  transform: scale(1.27201965);
}
.x6-toolbar-item-text {
  padding-left: 4px;
  font-size: 12px;
}
.x6-toolbar-item-dropdown-wrap {
  margin-top: -13px;
}
.x6-toolbar-item-dropdown-arrow {
  width: 0;
  height: 0;
  margin-left: 6px;
  border-color: #262626 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  opacity: 0.4;
}
.x6-toolbar.x6-toolbar-hover-effect {
  height: 32px;
}
.x6-toolbar.x6-toolbar-hover-effect .x6-toolbar-item {
  margin: 4px 2px 4px 0;
  padding: 0 6px;
  color: #595959;
}
.x6-toolbar.x6-toolbar-hover-effect .x6-toolbar-item-disabled.x6-toolbar-item:hover {
  color: #595959;
  background-color: transparent;
}
.x6-toolbar.x6-toolbar-hover-effect .x6-toolbar-item-disabled.x6-toolbar-item:hover .x6-toolbar-item-dropdown-arrow {
  opacity: 0.4;
}
.x6-toolbar-item.x6-toolbar-item-active,
.x6-toolbar.x6-toolbar-hover-effect .x6-toolbar-item.x6-toolbar-item-active,
.x6-toolbar.x6-toolbar-hover-effect .x6-toolbar-item:hover {
  color: #262626;
  background-color: #e0e0e0;
}
.x6-toolbar-item.x6-toolbar-item-active .x6-toolbar-item-dropdown-arrow,
.x6-toolbar.x6-toolbar-hover-effect .x6-toolbar-item.x6-toolbar-item-active .x6-toolbar-item-dropdown-arrow,
.x6-toolbar.x6-toolbar-hover-effect .x6-toolbar-item:hover .x6-toolbar-item-dropdown-arrow {
  opacity: 0.75;
}
.x6-toolbar-item.x6-toolbar-item-active {
  margin: 4px 0;
}
.x6-toolbar.x6-toolbar-big {
  height: 32px;
}
.x6-toolbar.x6-toolbar-big .x6-toolbar-content-extras {
  font-size: 16px;
}
.x6-toolbar.x6-toolbar-big .x6-toolbar-item {
  margin: 4px 4px 4px 0;
  padding: 0 5px;
  border-radius: 3px;
}
.x6-toolbar.x6-toolbar-big .x6-toolbar-item-text {
  font-size: 14px;
}
.x6-toolbar.x6-toolbar-big .x6-toolbar-item-icon {
  font-size: 16px;
}
.x6-toolbar.x6-toolbar-hover-effect.x6-toolbar-big {
  height: 40px;
}
.x6-toolbar.x6-toolbar-hover-effect.x6-toolbar-big .x6-toolbar-item {
  padding: 0 10px;
}
.x6-toolbar.x6-toolbar-small {
  height: 22px;
}
.x6-toolbar.x6-toolbar-small .x6-toolbar-content-extras {
  font-size: 12px;
}
.x6-toolbar.x6-toolbar-small .x6-toolbar-item {
  padding: 0 3px;
}
.x6-toolbar.x6-toolbar-small .x6-toolbar-item-text {
  margin-right: 2px;
  padding-left: 2px;
  font-size: 10px;
}
.x6-toolbar.x6-toolbar-small .x6-toolbar-item-icon {
  font-size: 12px;
}
.x6-toolbar.x6-toolbar-small .x6-toolbar-item.x6-toolbar-item-active {
  margin: 2px 0;
}
.x6-toolbar.x6-toolbar-hover-effect.x6-toolbar-small {
  height: 28px;
}
.x6-toolbar.x6-toolbar-hover-effect.x6-toolbar-small .x6-toolbar-item {
  margin: 4px 2px 4px 0;
  padding: 0 5px;
}
.x6-toolbar.x6-toolbar-hover-effect.x6-toolbar-small .x6-toolbar-item.x6-toolbar-item-active {
  margin: 4px 2px 4px 0;
}
.x6-toolbar.x6-toolbar-align-right .x6-toolbar-content {
  flex-direction: row-reverse;
}
.xflow-toolbar {
  position: absolute;
  z-index: 99;
  display: flex;
  height: 40px;
  background: #fff;
}
.xflow-toolbar-root {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.xflow-toolbar-root.horizontal-center {
  justify-content: center;
}
.xflow-toolbar-root.vertical {
  flex-direction: column;
}
.xflow-toolbar-root .x6-toolbar.x6-toolbar-hover-effect {
  align-items: center;
  height: 100%;
}
.xflow-toolbar-root .x6-toolbar-content {
  height: 100%;
}
.xflow-toolbar.vertical {
  right: unset;
  left: unset;
  width: 32px;
  height: auto;
  border: 1px solid rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  box-shadow: 0 0 20px rgb(0 0 0 / 1%);
}
.xflow-toolbar.vertical .x6-toolbar.x6-toolbar-hover-effect {
  height: auto;
  padding: 8px 0;
}
.xflow-toolbar.vertical .x6-toolbar-content-inner,
.xflow-toolbar.vertical .x6-toolbar-group {
  flex-direction: column;
}
.xflow-toolbar.vertical .x6-toolbar-item {
  margin: 0;
  padding: 6px 8px;
  color: #595959;
}
.xflow-toolbar.horizontal {
  right: 0;
  left: 0;
}
.xflow-toolbar.horizontal .x6-toolbar.x6-toolbar-hover-effect {
  height: 40px;
  line-height: 40px;
}
.flowchart-extension-container .x6-edge {
  stroke-width: 1px;
}
.flowchart-extension-container .x6-edge.success path:nth-child(2) {
  stroke: #888 !important;
}
.flowchart-extension-container .x6-edge.success path:nth-child(3) {
  fill: #888 !important;
  stroke: #888 !important;
}
.flowchart-extension-container .x6-edge.error {
  stroke-width: 2px;
}
.flowchart-extension-container .x6-edge.error path:nth-child(2) {
  stroke: rgba(245, 34, 45, 0.45) !important;
  stroke-width: 2px;
}
.flowchart-extension-container .x6-edge.guideProcessing path:nth-child(2) {
  stroke: rgba(57, 202, 116, 0.8);
  stroke-width: 2px;
  stroke-dasharray: 8px, 2px;
}
.flowchart-extension-container .x6-edge.guideProcessing path:nth-child(2):local {
  animation: processing-line 30s infinite linear;
}
.flowchart-extension-container .x6-edge.x6-edge-selected path:nth-child(2) {
  stroke: #1890ff;
  stroke-width: 2px;
}
.flowchart-extension-container .x6-edge:hover path:nth-child(2) {
  stroke: #1890ff;
  stroke-width: 2px;
}
.flowchart-extension-container .x6-edge.hoverHighlight path:nth-child(2) {
  stroke: #1890ff;
  stroke-width: 2px;
}
@keyframes processing-line {
  to {
    stroke-dashoffset: -1000;
  }
}
.flowchart-extension-container .x6-widget-transform {
  margin: -1px 0 0 -1px;
  padding: 0;
  border: 1px solid #239edd;
}
.flowchart-extension-container .x6-widget-transform > div {
  border: 1px solid #239edd;
}
.flowchart-extension-container .x6-widget-transform > div:hover {
  background-color: #3dafe4;
}
.flowchart-extension-container .x6-widget-transform-active-handle {
  background-color: #3dafe4;
}
.flowchart-extension-container .x6-widget-transform-resize {
  border-radius: 0;
}
.flowchart-extension-container .x6-widget-selection-inner {
  border: 1px solid #239edd;
}
.flowchart-extension-container .x6-widget-selection-box {
  opacity: 0;
}
.flowchart-extension-container.xflow-app-workspace {
  overflow: hidden;
  border: 1px solid #d9d9d9;
}
.flowchart-extension-container.xflow-app-workspace .xflow-workspace-panel {
  z-index: 1;
  background: #fff;
}
.flowchart-extension-container.xflow-app-workspace svg {
  overflow: visible !important;
}
.flowchart-extension-container.xflow-app-workspace .x6-edge {
  stroke-width: 1px;
}
.flowchart-extension-container.xflow-app-workspace .x6-edge.x6-edge-selected path:nth-child(2) {
  stroke: #1890ff;
}
.flowchart-extension-container.xflow-app-workspace .x6-edge:hover path:nth-child(2) {
  stroke: #1890ff;
}
.flowchart-extension-container.xflow-app-workspace .x6-edge.hoverHighlight path:nth-child(2) {
  stroke: #1890ff;
}
.flowchart-extension-container.xflow-app-workspace .x6-port > circle {
  stroke: #69c0ff;
}
.flowchart-extension-container.xflow-app-workspace .x6-widget-transform {
  box-sizing: border-box !important;
  margin: 0;
  padding: 0;
  border: 2px solid #3572f9;
  box-shadow: 0 4px 4px 0 #dbe6ff;
}
.flowchart-extension-container.xflow-app-workspace .x6-widget-transform > div {
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 1px solid #3572f9;
}
.flowchart-extension-container.xflow-app-workspace .xflow-json-form .ant-tabs-content-holder {
  padding: 0 !important;
}
.flowchart-container-collpase {
  transition: left 0.5s;
}
.flowchart-container-collpase-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}
.flowchart-container-collpase-nodes {
  border-right: 1px solid #d9d9d9;
}
.flowchart-container-collpase-icon {
  position: absolute;
  z-index: 99;
  width: 20px;
  color: #aaa;
  font-size: 12px;
  text-align: center;
  background: #fff;
  border: 1px solid #ccc;
  transform: translate(0, -50%);
  cursor: pointer;
}
.flowchart-container-collpase-icon:hover {
  color: #2b84c0;
}
.flowchart-container-collpase .xflow-workspace-panel {
  transition: left 0.5s;
}
.flowchart-editor-panel-body {
  padding: 12px;
}
.flowchart-editor-panel-body .flowchart-editor-color-container {
  width: 24px;
  height: 24px;
  padding: 4px;
  border: 1px solid #eee;
  border-radius: 2px;
}
.flowchart-editor-panel-body .flowchart-editor-panel-group {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  margin-bottom: 12px;
  padding-bottom: 12px;
  font-size: 12px;
}
.flowchart-editor-panel-body .flowchart-editor-panel-group:first-child {
  border-bottom: 1px solid #ccc;
}
.flowchart-editor-panel-body .flowchart-editor-panel-group:last-child {
  margin-bottom: 0;
}
.flowchart-editor-panel-body .flowchart-editor-panel-group input,
.flowchart-editor-panel-body .flowchart-editor-panel-group select {
  height: 24px;
}
.flowchart-editor-panel-body .flowchart-editor-panel-group h5 {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
}
.flowchart-editor-panel-body .flowchart-editor-panel-group .group {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.flowchart-editor-panel-body .flowchart-editor-panel-group .group > label {
  margin-right: 8px;
  color: rgba(0, 0, 0, 0.45);
  word-break: keep-all;
}
.flowchart-editor-panel-body .flowchart-editor-panel-group .split {
  display: flex;
  grid-gap: 8px;
}
.flowchart-editor-panel-body .flowchart-editor-panel-group .addon-before-group {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow: hidden;
  vertical-align: center;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.flowchart-editor-panel-body .flowchart-editor-panel-group .addon-before-group > span {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 20px;
  color: #000000d9;
  line-height: 24px;
  text-align: center;
  background-color: #fafafa;
  cursor: pointer;
}
.flowchart-editor-panel-body .flowchart-editor-panel-group .addon-before-group:hover > span {
  display: none;
}
.flowchart-editor-panel-body .flowchart-editor-node-text-style,
.flowchart-editor-panel-body .flowchart-editor-edge-text-style,
.flowchart-editor-panel-body .flowchart-editor-edge-stroke-style {
  display: flex;
  flex-direction: row;
  grid-gap: 8px;
}
.flowchart-editor-panel-body .ant-input-number {
  width: 100%;
}
.flowchart-editor-canvas-panel {
  display: flex;
  justify-content: center;
  padding-top: 60px;
  color: #aaa;
}
.flowchart-editor-pick-color-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.25);
}
.flowchart-editor-pick-color-container .flowchart-editor-popover {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 12px;
  background: #fff;
  transform: translate(-50%, -50%);
}
.flowchart-editor-pick-color-container .sketch-picker {
  box-sizing: border-box !important;
  padding: 0 !important;
  border-radius: none !important;
  box-shadow: none !important;
}
.flowchart-editor-pick-color-container .foolter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 12px;
}
.flowchart-extension-container .xflow-editor-panel-collpase {
  color: rgba(0, 0, 0, 0.85);
  font-size: 12px;
  transition: right 0.5s;
}
.flowchart-extension-container .xflow-editor-panel-collpase-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}
.flowchart-extension-container .xflow-editor-panel-collpase-icon {
  position: absolute;
  z-index: 99;
  width: 20px;
  color: #aaa;
  text-align: center;
  background: #fff;
  border: 1px solid #ccc;
  transform: translate(0, -50%);
  cursor: pointer;
}
.flowchart-extension-container .xflow-editor-panel-collpase-icon:hover {
  color: #2b84c0;
}
.flowchart-extension-container .xflow-editor-panel-collpase .xflow-workspace-panel {
  transition: left 0.5s;
}
.xflow-group-node {
  z-index: 9;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.65);
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 4px;
  box-shadow: rgb(17 49 96 / 12%) 0px 1px 3px 0px, rgb(17 49 96 / 4%) 0px 0px 0px 1px;
  cursor: grab;
}
.xflow-group-node:hover {
  background-color: rgba(227, 244, 255, 0.45);
  border: 1px solid #1890ff;
  box-shadow: 0 0 3px 3px rgba(64, 169, 255, 0.2);
  cursor: move;
}
.xflow-group-node .xflow-group-header {
  display: flex;
  justify-content: space-between;
  padding: 0 12px;
  line-height: 38px;
}
.xflow-group-node .xflow-group-header .header-left {
  width: 80%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.xflow-group-node .xflow-group-header .header-right {
  display: inline-flex;
  align-items: center;
}
.xflow-group-node .xflow-group-header .header-right span.anticon {
  margin-left: 8px;
}
.x6-node-selected .xflow-group-node {
  background-color: rgba(243, 249, 255, 0.92);
  border: 1px solid #1890ff;
  box-shadow: 0 0 3px 3px rgba(64, 169, 255, 0.2);
}
.x6-node-selected .xflow-group-node:hover {
  background-color: rgba(243, 249, 255, 0.6);
}
.flowchart-extension-container .xflow-node-panel {
  color: rgba(0, 0, 0, 0.85);
  font-size: 12px;
}
.flowchart-extension-container .xflow-node-panel-header {
  display: flex;
  justify-content: space-evenly;
  background: #fff;
  border-right: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
}
.flowchart-extension-container .xflow-node-panel-header-title {
  font-size: 16px;
}
.flowchart-extension-container .xflow-node-panel-header-search {
  width: 100%;
  padding: 0 12px;
}
.flowchart-extension-container .xflow-node-panel-custom {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 5px;
  background: #fff;
  cursor: grab;
}
.flowchart-extension-container .xflow-node-panel-official {
  grid-gap: 5px;
  background: #fff;
  cursor: grab;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(24px, 1fr));
}
.flowchart-extension-container .xflow-node-panel-node-wrapper {
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.flowchart-extension-container .xflow-node-panel .ant-collapse-content-box {
  padding: 12px;
}
.flowchart-extension-container .xflow-node-panel .ant-collapse-content {
  border-top: none;
}
.flowchart-extension-container .xflow-node-panel .ant-collapse-header {
  color: rgba(0, 0, 0, 0.85);
  font-size: 12px;
}
.flowchart-extension-container .xflow-node-panel-collpase {
  color: rgba(0, 0, 0, 0.85);
  font-size: 12px;
  transition: left 0.5s;
}
.flowchart-extension-container .xflow-node-panel-collpase-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}
.flowchart-extension-container .xflow-node-panel-collpase-nodes {
  border-right: 1px solid #d9d9d9;
}
.flowchart-extension-container .xflow-node-panel-collpase-icon {
  position: absolute;
  z-index: 99;
  width: 20px;
  color: #aaa;
  text-align: center;
  background: #fff;
  border: 1px solid #ccc;
  transform: translate(0, -50%);
  cursor: pointer;
}
.flowchart-extension-container .xflow-node-panel-collpase-icon:hover {
  color: #2b84c0;
}

.xflow-app-workspace {
  overflow: hidden;
  border: 1px solid #d9d9d9;
}
.xflow-app-workspace .xflow-workspace-panel {
  z-index: 1;
  background: #fff;
}
.xflow-app-workspace svg {
  overflow: visible !important;
}
.xflow-app-workspace .x6-edge {
  stroke-width: 1px;
}
.xflow-app-workspace .x6-edge.x6-edge-selected path:nth-child(2) {
  stroke: #3572f9;
}
.xflow-app-workspace .x6-edge:hover path:nth-child(2) {
  stroke: #3572f9;
}
.xflow-app-workspace .x6-edge.hoverHighlight path:nth-child(2) {
  stroke: #3572f9;
}
.xflow-app-workspace .x6-port > circle {
  stroke: #69c0ff;
}
.xflow-app-workspace .x6-widget-transform {
  box-sizing: border-box !important;
  margin: 0;
  padding: 0;
  border: 2px solid #3572f9;
  box-shadow: 0 4px 4px 0 #dbe6ff;
}
.xflow-app-workspace .x6-widget-transform > div {
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 1px solid #3572f9;
}
.xflow-app-workspace .xflow-json-form .ant-tabs-content-holder {
  padding: 0 !important;
}
.xflow-app-workspace .ant-collapse-header {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  padding: 12px 16px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.5715;
  cursor: pointer;
  transition: all 0.3s, visibility 0s;
}
